import { createRouter, createWebHashHistory } from 'vue-router'
import Home from "@/views/Home.vue";
import Contact from "@/views/Contact.vue";
import Gallery from "@/views/Gallery.vue";
import Pricing from "@/views/Pricing.vue";
const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact,
  },
  {
    path: '/gallery',
    name: 'gallery',
    component: Gallery,
  },
  {
    path: '/pricing',
    name: 'pricing',
    component: Pricing,
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
