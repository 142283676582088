<template>
  <div>
    <NavBar>
    </NavBar>
    <v-container class="contact-section"
                 fluid
                 style="background-color: #a7cfcf">
      <v-row justify="center"
             align="center">
        <v-col cols="12"
               md="6">
          <v-card class="contact-card d-flex flex-column align-center justify-center">
            <v-img src="/images/maddie2.jpg"
                   alt="Contact Image"
                   class="rounded-image-contact">
            </v-img>
            <v-card-title class="contact-title">
              Contact Me
            </v-card-title>
            <v-card-subtitle class="contact-info d-flex align-center justify-center">
              Madison Bergmann
            </v-card-subtitle>
            <v-card-subtitle class="contact-info d-flex align-center justify-center">
              +1 (330) 421-4334
            </v-card-subtitle>
            <v-card-subtitle class="contact-info d-flex align-center justify-center">
              contact@pawsitivepetcare.com
            </v-card-subtitle>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import NavBar from "@/views/components/NavBar.vue";

export default {
  name: "Contact",
  components: {
    NavBar,
  },
};
</script>
<style scoped>
.contact-section {
  padding: 60px 0;
  background-color: #f3f3f3;
}

.contact-card {
  padding: 20px;
  background-color: #95cccc;
  border-radius: 10px;
  text-align: center;
}

.rounded-image-contact {
  border-radius: 10px;
  width: 100%;
  max-width: 300px;
  margin: 0 auto 20px;
  border: 7px solid white;
}

.contact-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: white;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 1);
}

.contact-info {
  font-size: 18px;
  color: white;
  font-weight: bold;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 1);
}
</style>
