<template>
  <div>
    <NavBar />

    <!-- Scroll Arrow -->
    <v-icon v-if="showScrollArrow" class="scroll-arrow" @click="scrollToContent">
      mdi-arrow-down
    </v-icon>

    <!-- Hero Section -->
    <v-container fluid class="hero-section">
      <v-row align="center" justify="center">
        <v-col cols="12" md="8" class="text-center">
          <h1 class="hero-title">Welcome to Pawsitive Pet Care</h1>
          <p class="lead pb-4">
            Offering love and care for your furry friends while you're away.
          </p>
          <v-btn large color="grey">Get Started</v-btn>
        </v-col>
        <v-col cols="12" md="4">
          <v-img src="/images/1.JPG" class="rounded-image" max-width="200" />
        </v-col>
      </v-row>
    </v-container>

    <!-- About Section -->
    <v-container fluid class="about-section" ref="aboutSection">
      <h2 class="section-title text-center">Who We Are</h2>
      <v-row align="center" justify="center">
        <v-col cols="2" md="2">
          <v-img src="/images/maddie1.jpg" class="rounded-image-person" max-width="200" />
        </v-col>
        <v-col cols="4" md="8">
          <v-card class="about-card">
            <v-card-text>
              <p class="about-text">
                At Pawsitive Pet Care, we are passionate about providing exceptional care for your beloved pets.
                We understand that pets are more than just animals—they are cherished members of your family. Whether you're at work, on vacation, or simply need an extra hand,
                I am here to ensure that your pets receive the attention, love, and exercise they need.
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- Services Section -->
    <v-container fluid class="services-section">
      <h2 class="section-title text-center">Our Services</h2>
      <v-row>
        <v-col cols="12" md="4">
          <v-card class="service-card hover-card" @click="openServiceSheet('Dog Walking', 'Daily exercise and fun for your dog')">
            <v-card-title>Dog Walking</v-card-title>
            <v-card-subtitle>Daily exercise and fun for your dog</v-card-subtitle>
          </v-card>
        </v-col>
        <v-col cols="12" md="4">
          <v-card class="service-card hover-card" @click="openServiceSheet('Playtime', 'Engaging play sessions for active pets')">
            <v-card-title>Playtime</v-card-title>
            <v-card-subtitle>Engaging play sessions for active pets</v-card-subtitle>
          </v-card>
        </v-col>
        <v-col cols="12" md="4">
          <v-card class="service-card hover-card" @click="openServiceSheet('Overnight Sitting', 'Comfortable care for your pets overnight')">
            <v-card-title>Overnight Sitting</v-card-title>
            <v-card-subtitle>Comfortable care for your pets overnight</v-card-subtitle>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- Customers Section -->
    <v-container fluid class="customers-section">
      <h2 class="section-title text-center">Meet Our Happy Customers</h2>
      <v-row>
        <v-col v-for="(customer, index) in customers" :key="index" cols="12" md="4">
          <v-card class="customer-card hover-card custom-shape">
            <v-card-title>{{ customer.name }}</v-card-title>
            <v-list dense style="background-color: transparent">
              <v-list-item v-for="(pet, petIndex) in customer.pets" :key="petIndex">
                <v-list-item-title class="pet-name">{{ pet.name }} ({{ pet.type }})</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- Bottom Sheet for Service Details -->
    <v-bottom-sheet v-model="sheetVisible">
      <v-card>
        <v-card-title>{{ selectedService.title }}</v-card-title>
        <v-card-text>{{ selectedService.description }}</v-card-text>
        <v-card-subtitle>{{ selectedService.detailedDescription }}</v-card-subtitle>
        <v-btn @click="closeSheet">Close</v-btn>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>

<script>
import NavBar from '@/views/components/NavBar.vue';

export default {
  name: 'Home',
  components: {
    NavBar,
  },
  data() {
    return {
      sheetVisible: false,
      showScrollArrow: true,
      selectedService: {
        title: '',
        description: '',
        detailedDescription: ''
      },
      customers: [
        { name: 'Jane Doe', pets: [{ name: 'Buddy', type: 'Dog' }, { name: 'Whiskers', type: 'Cat' }] },
        { name: 'John Smith', pets: [{ name: 'Max', type: 'Dog' }] },
        { name: 'Alice Johnson', pets: [{ name: 'Bella', type: 'Puppy' }] },
      ],
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    openServiceSheet(title, description) {
      this.selectedService.title = title;
      this.selectedService.description = description;
      this.selectedService.detailedDescription = this.getDetailedDescription(title);
      this.sheetVisible = true;
    },
    getDetailedDescription(title) {
      if (title === 'Dog Walking') {
        return 'Our dog walking service provides daily exercise for your furry friends to keep them healthy and happy. We take them to their favorite spots!';
      } else if (title === 'Playtime') {
        return 'Playtime is all about engaging fun activities for your pets, ensuring they get the stimulation they need through toys and games.';
      } else if (title === 'Overnight Sitting') {
        return 'Our overnight sitting service ensures that your pet is comfortable, secure, and cared for while you’re away for the night.';
      }
    },
    closeSheet() {
      this.sheetVisible = false;
    },
    handleScroll() {
      if (window.scrollY > 50) {
        this.showScrollArrow = false;
      } else {
        this.showScrollArrow = true;
      }
    },
    scrollToContent() {
      const aboutSection = this.$refs.aboutSection;
      if (aboutSection) {
        aboutSection.scrollIntoView({ behavior: 'smooth' });
      }
    },
  },
};
</script>

<style scoped>
.text-center {
  text-align: center;
}

.hero-section {
  background-color: #95cccc;
  padding: 100px 0;
  color: white;
}

.hero-title {
  font-size: 48px;
  font-weight: bold;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 1);
}

.cta-button {
  background-color: #cc3581;
  color: white;
  border-radius: 30px;
  padding: 10px 30px;
}

.scroll-arrow {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 48px;
  color: white;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.about-section {
  background-color: #a7cfcf;
  padding: 60px 0;
}

.about-card {
  background-color: white;
  border-radius: 20px;
  padding: 20px;
}

.about-text {
  color: #6D4C41;
  font-size: 18px;
}

.services-section {
  background-color: #a7cfcf;
  padding: 60px 0;
}

.section-title {
  color: white;
  margin-bottom: 40px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 1);
}

.service-card {
  background-color: white;
  text-align: center;
  padding: 30px;
  border-radius: 20px;
}

.customers-section {
  background-color: #a7cfcf;
  padding: 60px 0;
}

.customer-card {
  background-color: white;
  text-align: center;
  border-radius: 15px;
}

.hover-card {
  transition: transform 0.3s, background-color 0.3s;
}

.hover-card:hover {
  background-color: #95cccc !important;
  transform: scale(1.05);
}

.pet-name {
  color: black;
  background-color: transparent;
  padding: 5px 0;
  font-weight: bold;
}

.rounded-image {
  border-radius: 15px;
  border: 3px solid #04bfbf;
  margin-top: 20px;
  width: 100%;
  max-width: 200px;
}
.rounded-image-person {
  border-radius: 15px;
  border: 3px solid #04bfbf;
}
</style>
