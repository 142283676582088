<template>
  <NavBar>
  </NavBar>
  <v-container style="background-color: #a7cfcf"
               fluid>
    <v-row>
      <v-col cols="12">
        <v-card style="background-color: #95cccc">
          <v-card-title class="text-center gallery-card-title">
            Pawsitive Pet Care Photo Gallery
          </v-card-title>
          <p class="gallery-card-text">
            Sorry, the photo gallery is still under construction, please try again later.
          </p>
          <v-card-actions>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import NavBar from "@/views/components/NavBar.vue";

export default {
  name: 'Gallery',
  components: {
    NavBar,
  },
}
</script>
<style>
.gallery-card-text {
  font-size: 18px;
  color: white;
  font-weight: bold;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 1);
}

.gallery-card-title {
  font-size: 24px;
  color: white;
  font-weight: bold;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 1);
}
</style>
