<template>
  <v-app-bar app color="#04bfbf" dark>
    <v-row align="center" justify="space-between" no-gutters>
      <!-- Logo / Title -->
      <v-col cols="6" md="4">
        <v-toolbar-title>
          <strong>
            <p class="title ml-4">Pawsitive Pet Care</p>
          </strong>
        </v-toolbar-title>
      </v-col>

      <!-- Nav Links for Larger Screens (Aligned Right) -->
      <v-col cols="12" md="8" class="d-none d-md-flex justify-end">
        <v-row justify="end" align="center">
          <v-btn text :to="{ name: 'home' }" color="white">Home</v-btn>
          <v-btn text :to="{ name: 'gallery' }" color="white">Gallery</v-btn>
          <v-btn text :to="{ name: 'contact' }" color="white">Contact</v-btn>
          <v-btn text :to="{ name: 'pricing' }" color="white">Pricing</v-btn>
        </v-row>
      </v-col>

      <!-- Hamburger Icon for Mobile -->
      <v-col cols="6" class="d-flex justify-end d-md-none">
        <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      </v-col>
    </v-row>

    <!-- Navigation Drawer for Mobile -->
    <v-navigation-drawer v-model="drawer" app temporary>
      <v-list>
        <v-list-item @click="drawer = false" :to="{ name: 'home' }">Home</v-list-item>
        <v-list-item @click="drawer = false" :to="{ name: 'gallery' }">Gallery</v-list-item>
        <v-list-item @click="drawer = false" :to="{ name: 'contact' }">Contact</v-list-item>
        <v-list-item @click="drawer = false" :to="{ name: 'pricing' }">Pricing</v-list-item>
      </v-list>
    </v-navigation-drawer>
  </v-app-bar>
</template>

<script>
export default {
  data() {
    return {
      drawer: false, // Controls the navigation drawer visibility
    };
  },
};
</script>

<style scoped>
.title {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  color: white;
  font-size: 24px;
  white-space: nowrap; /* Prevent title from breaking */
}

/* Move buttons to the right on medium and large screens */
.d-md-flex {
  display: flex;
  justify-content: flex-end;
}

.v-row {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

/* Button Styling */
.v-btn {
  text-transform: none;
  margin-right: 10px; /* Add space between buttons */
}

/* Align mobile menu icon */
.v-app-bar-nav-icon {
  margin-right: 16px;
}

/* Drawer background color */
.v-navigation-drawer {
  background-color: #04bfbf;
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .title {
    font-size: 20px;
    margin-left: 8px;
  }
  .v-btn {
    font-size: 14px; /* Smaller buttons on small screens */
  }
}
</style>
